<template>
  <table :class="{mainTable: table == 'main', moreTable: table =='more'}">
    <!--colgroup>
      <col width="10%">
      <col width="11%">
      <col width="11%">
      <col width="11%">
      <col v-if="gameGroupList.includes('casino')" width="10%">
      <col v-if="gameGroupList.includes('sport')" width="10%">
      <col v-if="gameGroupList.includes('slot')" width="10%">
      <col v-if="gameGroupList.includes('minigame')" width="10%">
      <col width="10%">
    </colgroup-->

    <thead v-if="table === 'main'">
      <tr>
        <th style="width:130%;">{{ $t('table.head.memIdNick') }}</th>
        <!-- <th v-if="gameGroupList.includes('casino')">{{ $t('table.head.casinoRate') }}</th>
        <th v-if="gameGroupList.includes('slot')">{{ $t('table.head.slotRate') }}</th>
        <th>{{ $t('table.head.miniRate') }}</th> -->
        <th>{{ $t('table.head.appliedRate') }} (%)</th>
        <!-- <th>{{ $t('table.head.member') }}</th> -->
        <th style="width:120%;">{{ $t('table.head.holdStatus') }}</th>
        <!-- <th>{{ $t('table.head.submoney') }}</th> -->
        <th>{{ $t('table.head.cashDiff') }}</th>
        <th v-if="gameGroupList.includes('casino')">{{ $t('table.head.casino') }}</th>
        <th v-if="gameGroupList.includes('sport')">{{ $t('table.head.sport') }}</th>
        <th v-if="gameGroupList.includes('slot')">{{ $t('table.head.slot') }}</th>
        <th v-if="gameGroupList.includes('minigame')">{{ $t('table.head.mini') }}</th>
        <th style="width:110%;">{{ $t('table.head.totalBet') }}</th>
      </tr>
    </thead>

    <tbody>
      <template  v-if="list.length > 0" >
        <all-to-tr v-for="item in list" :data="item" :reqData="reqData" :level="level" :key="item.memId" :type="type" :isBS="isBS" :isAll="isAll" :isDiff="isDiff"
        ></all-to-tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="12">{{ $t('txt.nodata') }}</td>
        </tr>
      </template>
    </tbody>

  </table>
</template>

<script>
import AllToTr from '@/components/ui/report/allToTr.vue'
import { mapState } from 'vuex'

export default {
  name: 'allToTable',
  inheritAttrs: false,
  components: {
    AllToTr
  },
  props: [
    'table',
    'headInfo',
    'list',
    'reqData',
    'level',
    'type',
    'isBS',
    'isDiff',
    'isAll'
  ],
  computed: {
    ...mapState([
      'gameGroupList'
    ])
  }
}
</script>

<style scoped>
th {
  width: 100%;
}
.mainTable, .moreTable {table-layout: fixed;}
.mainTable thead {border: 0; box-shadow: none;}
.mainTable th {border-right: 3px solid #efefef; border-bottom: 3px solid #efefef; height: 28px; color: #585963; background: #dfdfdf; box-sizing: border-box;}
.mainTable th:last-child {border-right:0;}
</style>
