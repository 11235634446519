import httpClient from '@/libs/http-client'

// commons
const nullToQuote = (data) => {
  return !data ? '' : data
}

export function calculateSearch (params) {
  const url = '/api/admin/report/calculateSearch'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function calculateSearchByUser (params) {
  const url = '/api/admin/report/reportByUser'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function dailyCalculate (params) {
  const url = '/api/admin/report/dailyCalculate'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function dailyCalculateForPartner (params) {
  const url = '/api/admin/retail/hierarchy'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
