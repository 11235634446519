<template>
  <tr>
    <td class="name" style="width:130%;">
      <span>
        <template v-if="level > 0">
          <a class="moreT">
            <template v-for="i in level">
              <img src="@/assets/img/box.svg" />
            </template>
          </a>
        </template>
        <div style="display:flex; gap: 3px; align-items: center;">
          <em class="box" :class="data.partnerLevel">{{computedPartnerLevel}}</em><!-- v-if="type !== 'user'" -->
          <div style="display:flex; flex-direction: column; gap: 4px;">
            <em class="block">
              <a @click="detailOpen('partner', data.memId)" class="pid">{{data.memId}}</a>
            </em>
            <em class="block" @click="detailOpen('partner', data.memId)">{{data.memNick}} ({{ numberWithCommas(data.cntBottom) }})</em>
          </div>
        </div>
      </span>
      <a class="moreBtns" @click="getMore" v-if="Number(data.cntPartner) > 0" :class="{'hiddenTable': inSearchList.length > 0 }"></a>
      <a class="moreBtns else" v-if="Number(data.cntPartner) == 0" ></a>
    </td>
    <td>
      <ul class="ratebox">
        <li v-if="gameGroupList.includes('casino')">
          <ul class="rateWrap">
            <li>{{ $t('table.head.casino') }}</li>
            <li>R<span>: {{inSearchTotal.casinoRate || data.casinoRate}}</span></li>
            <li>L<span>: {{inSearchTotal.casinoLoseRate || data.casinoLoseRate}}</span></li>
          </ul>
        </li>
        <li v-if="gameGroupList.includes('sport')">
          <ul class="rateWrap">
            <li>{{ $t('table.head.sport') }}</li>
            <li>R<span>: {{inSearchTotal.sportRate || data.sportRate}}</span></li>
            <li>L<span>: {{inSearchTotal.sportLoseRate || data.sportLoseRate}}</span></li>
          </ul>
        </li>
        <li v-if="gameGroupList.includes('slot')">
          <ul class="rateWrap">
            <li>{{ $t('table.head.slot') }}</li>
            <li>R<span>: {{inSearchTotal.slotRate || data.slotRate}}</span></li>
            <li>L<span>: {{inSearchTotal.slotLoseRate || data.slotLoseRate}}</span></li>
          </ul>
        </li>
        <li v-if="gameGroupList.includes('minigame')">
          <ul class="rateWrap">
            <li>{{ $t('table.head.mini') }}</li>
            <li>R<span>: {{inSearchTotal.miniRate || data.miniRate}}</span></li>
            <li>L<span>: {{inSearchTotal.miniLoseRate || data.miniLoseRate}}</span></li>
          </ul>
        </li>
      </ul>
    </td>
    <td class="money" style="width:120%;">
      <ul>
        <li><em>{{ $t('table.body.money') }}</em>{{ numberWithCommas(inSearchTotal.cashAmt || data.cashAmt) }}</li>
        <li><em>{{ $t('table.body.pointAmt') }}</em>{{ numberWithCommas(inSearchTotal.pointAmt || data.pointAmt) }}</li>
        <li class=""><em>{{ $t('table.body.submoney') }}</em>{{ numberWithCommas(inSearchTotal.botCashAmt || data.botCashAmt) }}</li>
        <li class=""><em>{{ $t('table.body.subpointAmt') }}</em>{{ numberWithCommas(inSearchTotal.botPointAmt || data.botPointAmt) }}</li>
      </ul>
    </td>
    <td>
      <ul class="money">
        <li><em>{{ $t('table.body.userIn') }}</em><span>{{ numberWithCommas(inSearchTotal.userCashIn || data.userCashIn) }}</span></li>
        <li><em>{{ $t('table.body.userOut') }}</em><span>{{ numberWithCommas(inSearchTotal.userCashOut || data.userCashOut) }}</span></li>
        <li><em>{{ $t('table.body.partnerin_out') }}</em><span>{{ numberWithCommas(inSearchTotal.partnerResultAmt || data.partnerResultAmt) }}</span></li>
        <li><em>{{ $t('table.body.in_out') }}</em><span>{{ numberWithCommas(inSearchTotal.userCashResultAmt || data.userCashResultAmt) }}</span></li>
      </ul>
    </td>
    <td v-if="gameGroupList.includes('casino')">
      <ul class="money">
        <li class="border-bot">
          <em>{{ $t('common.bet') }}</em>
          <!-- <span v-if="!isBS">{{ numberWithCommas(inSearchTotal.casinoBetAmt || data.casinoBetAmt) }}</span>
          <span v-else>{{ numberWithCommas(inSearchTotal.casinoBetAmtBS || data.casinoBetAmtBS) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.casinoBetAmt || data.casinoBetAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.casinoBetAmtBS || data.casinoBetAmtBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.casinoBetAmt - inSearchTotal.casinoBetAmtBS || data.casinoBetAmt - data.casinoBetAmtBS) }}</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('common.win') }}</em>
          <!-- <span v-if="!isBS">{{ numberWithCommas(inSearchTotal.casinoBetAmtWin || data.casinoBetAmtWin) }}</span>
          <span v-else>{{ numberWithCommas(inSearchTotal.casinoBetAmtWinBS || data.casinoBetAmtWinBS) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.casinoBetAmtWin || data.casinoBetAmtWin) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.casinoBetAmtWinBS || data.casinoBetAmtWinBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.casinoBetAmtWin - inSearchTotal.casinoBetAmtWinBS || data.casinoBetAmtWin - data.casinoBetAmtWinBS) }}</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('common.rolling') }}</em>
          <!-- <span>{{ numberWithCommas(inSearchTotal.casinoRollingAmt || data.casinoRollingAmt) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.casinoRollingAmt || data.casinoRollingAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.casinoRollingAmt || data.casinoRollingAmt) }}</span>
          <span v-if="isDiff">0</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('common.losing')}}</em>
          <span>{{numberWithCommas(inSearchTotal.casinoLosingAmt || data.casinoLosingAmt ) }}</span>
        </li>
        <li>
          <em>{{ $t('common.cal') }}</em>
          <!-- <span v-if="!isBS" :class="{
            'rdc' : Number(inSearchTotal.casinoBetResultAmt || data.casinoBetResultAmt) > 0,
            'blc': Number(inSearchTotal.casinoBetResultAmt || data.casinoBetResultAmt) <= 0}">
            {{ numberWithCommas(inSearchTotal.casinoBetResultAmt || data.casinoBetResultAmt) }}
          </span>
          <span v-else :class="{
            'rdc' : Number(inSearchTotal.casinoBetResultAmtBS || data.casinoBetResultAmtBS) > 0,
            'blc': Number(inSearchTotal.casinoBetResultAmtBS || data.casinoBetResultAmtBS) <= 0}">
            {{ numberWithCommas(inSearchTotal.casinoBetResultAmtBS || data.casinoBetResultAmtBS) }}
          </span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.casinoBetResultAmt || data.casinoBetResultAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.casinoBetResultAmtBS || data.casinoBetResultAmtBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.casinoBetResultAmt - inSearchTotal.casinoBetResultAmtBS || data.casinoBetResultAmt - data.casinoBetResultAmtBS) }}</span>
        </li>
      </ul>
    </td>
    <td v-if="gameGroupList.includes('sport')"> <!--스포츠없을때도 나오게끔~-->
      <ul class="money">
        <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(inSearchTotal.sportBetAmt || data.sportBetAmt) }}</span></li>
        <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(inSearchTotal.sportBetAmtWin || data.sportBetAmtWin) }}</span></li>
        <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(inSearchTotal.sportRollingAmt || data.sportRollingAmt) }}</span></li>
        <li class="border-bot"><em>{{ $t('common.losing') }}</em><span>{{numberWithCommas( inSearchTotal.sportLosingAmt || data.sportLosingAmt) }}</span></li>
        <li><em>{{ $t('common.cal') }}</em>
          <span
            :class="{'rdc' : Number(inSearchTotal.sportBetResultAmt || data.sportBetResultAmt) > 0, 'blc': Number(inSearchTotal.sportBetResultAmt || data.sportBetResultAmt) <= 0}">
            {{ numberWithCommas(inSearchTotal.sportBetResultAmt || data.sportBetResultAmt) }}
          </span>
        </li>
      </ul>
    </td>
    <td v-if="gameGroupList.includes('slot')">
      <ul class="money">
        <li class="border-bot">
          <em>{{ $t('common.bet') }}</em>
          <!-- <span v-if="!isBS">{{ numberWithCommas(inSearchTotal.slotBetAmt || data.slotBetAmt) }}</span>
          <span v-else>{{ numberWithCommas(inSearchTotal.slotBetAmtBS || data.slotBetAmtBS) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.slotBetAmt || data.slotBetAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.slotBetAmtBS || data.slotBetAmtBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.slotBetAmt - inSearchTotal.slotBetAmtBS || data.slotBetAmt - data.slotBetAmtBS) }}</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('common.win') }}</em>
          <!-- <span v-if="!isBS">{{ numberWithCommas(inSearchTotal.slotBetAmtWin || data.slotBetAmtWin) }}</span>
          <span v-else>{{ numberWithCommas(inSearchTotal.slotBetAmtWinBS || data.slotBetAmtWinBS) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.slotBetAmtWin || data.slotBetAmtWin) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.slotBetAmtWinBS || data.slotBetAmtWinBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.slotBetAmtWin - inSearchTotal.slotBetAmtWinBS || data.slotBetAmtWin - data.slotBetAmtWinBS) }}</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('common.rolling') }}</em>
          <!-- <span>{{ numberWithCommas(inSearchTotal.slotRollingAmt || data.slotRollingAmt) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.slotRollingAmt || data.slotRollingAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.slotRollingAmt || data.slotRollingAmt) }}</span>
          <span v-if="isDiff">0</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('common.losing') }}</em>
          <span>{{numberWithCommas( inSearchTotal.slotLosingAmt || data.slotLosingAmt) }}</span>
        </li>
        <li>
          <em>{{ $t('common.cal') }}</em>
          <!-- <span v-if="!isBS" :class="{
            'rdc' : Number(inSearchTotal.slotBetResultAmt || data.slotBetResultAmt) > 0,
            'blc': Number(inSearchTotal.slotBetResultAmt || data.slotBetResultAmt) <= 0}">
            {{ numberWithCommas(inSearchTotal.slotBetResultAmt || data.slotBetResultAmt) }}
          </span>
          <span v-else :class="{
            'rdc' : Number(inSearchTotal.slotBetResultAmtBS || data.slotBetResultAmtBS) > 0,
            'blc': Number(inSearchTotal.slotBetResultAmtBS || data.slotBetResultAmtBS) <= 0}">
            {{ numberWithCommas(inSearchTotal.slotBetResultAmtBS || data.slotBetResultAmtBS) }}
          </span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.slotBetResultAmt || data.slotBetResultAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.slotBetResultAmtBS || data.slotBetResultAmtBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.slotBetResultAmt - inSearchTotal.slotBetResultAmtBS || data.slotBetResultAmt - data.slotBetResultAmtBS) }}</span>
        </li>
      </ul>
    </td>
    <td v-if="gameGroupList.includes('minigame')">
      <ul class="money">
        <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(inSearchTotal.miniBetAmt || data.miniBetAmt) }}</span></li>
        <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(inSearchTotal.miniBetAmtWin || data.miniBetAmtWin) }}</span></li>
        <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(inSearchTotal.miniRollingAmt || data.miniRollingAmt) }}</span></li>
        <li class="border-bot"><em>{{ $t('common.losing') }}</em><span>{{ numberWithCommas(inSearchTotal.miniLosingAmt || data.miniLosingAmt) }}</span></li>
        <li><em>{{ $t('common.cal') }}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.miniBetResultAmt || data.miniBetResultAmt) > 0, 'blc': Number(inSearchTotal.miniBetResultAmt || data.miniBetResultAmt) <= 0}">
            {{ numberWithCommas(inSearchTotal.miniBetResultAmt || data.miniBetResultAmt) }}
          </span>
        </li>
      </ul>
    </td>

    <td style="width:110%;">
      <ul class="money">
        <li class="border-bot">
          <em>{{ $t('table.head.totalBet') }}</em>
          <!-- <span v-if="!isBS">{{ numberWithCommas(inSearchTotal.betAmt || data.betAmt) }}</span>
          <span v-else>{{ numberWithCommas(inSearchTotal.betAmtBS || data.betAmtBS) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.betAmt || data.betAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.betAmtBS || data.betAmtBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.betAmt - inSearchTotal.betAmtBS || data.betAmt - data.betAmtBS) }}</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('table.body.totalBetWin') }}</em>
          <!-- <span v-if="!isBS">{{ numberWithCommas(inSearchTotal.betAmtWin || data.betAmtWin) }}</span>
          <span v-else>{{ numberWithCommas(inSearchTotal.betAmtWinBS || data.betAmtWinBS) }}</span> -->
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.betAmtWin || data.betAmtWin) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.betAmtWinBS || data.betAmtWinBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.betAmtWin - inSearchTotal.betAmtWinBS || data.betAmtWin - data.betAmtWinBS) }}</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('table.body.totalBetRoll') }}</em>
          <span>{{ numberWithCommas(inSearchTotal.rollingAmt || data.rollingAmt) }}</span>
        </li>
        <li class="border-bot">
          <em>{{ $t('table.body.totalBetLose') }}</em>
          <span>{{ numberWithCommas((inSearchTotal.casinoLosingAmt + inSearchTotal.slotLosingAmt) || (data.casinoLosingAmt + data.slotLosingAmt))}}</span>
        </li>
        <li>
          <em>{{ $t('table.body.totalBetCal') }}</em>
          <span v-if="isAll">{{ numberWithCommas(inSearchTotal.betResultAmt || data.betResultAmt) }}</span>
          <span v-if="isBS">{{ numberWithCommas(inSearchTotal.betResultAmtBS || data.betResultAmtBS) }}</span>
          <span v-if="isDiff">{{ numberWithCommas(inSearchTotal.betResultAmt - searchTotal.betResultAmtBS || data.betResultAmt - data.betResultAmtBS) }}</span>
          <!-- <span v-if="!isBS" :class="{
            'rdc' : Number(inSearchTotal.betResultAmt || data.betResultAmt) > 0,
            'blc': Number(inSearchTotal.betResultAmt || data.betResultAmt) <= 0}">
          {{ numberWithCommas(inSearchTotal.betResultAmt || data.betResultAmt) }}
          </span>
          <span v-else :class="{
            'rdc' : Number(inSearchTotal.betResultAmtBS || data.betResultAmtBS) > 0,
            'blc': Number(inSearchTotal.betResultAmtBS || data.betResultAmtBS) <= 0}">
          {{ numberWithCommas(inSearchTotal.betResultAmtBS || data.betResultAmtBS) }}
          </span> -->
        </li>
      </ul>
    </td>
  </tr>
  <tr v-if="inSearchList.length > 0">
    <td colspan="9">
      <component :is="component" v-if="inSearchList.length > 0" :key="data.memId"
                 :table="'more'"
                 :list="inSearchList"
                 :reqData="reqData"
                 :isBS="isBS"
                 :isAll="isAll"
                 :isDiff="isDiff"
                 :level="this.level + 1"
      />
    </td>
  </tr>
</template>

<script>
import { getDateStr } from '@/libs/utils'
import { calculateSearch } from '@/api/report'
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'allToTr',
  inheritAttrs: false,
  props: [
    'data',
    'reqData',
    'level',
    'type',
    'isBS',
    'isAll',
    'isDiff'
  ],
  computed: {
    ...mapState([
      'gameGroupList'
    ]),
    computedPartnerLevel () {
      return PARTNER_LEVEL[this.data.partnerLevel]
    },
    component () {
      return defineAsyncComponent(() => import('@/components/ui/report/allToTable'))
    }
  },
  mounted () {
  },
  data () {
    return {
      more: false,
      inSearchList: [],
      inSearchTotal: {},
      loader: false
    }
  },
  watch: {
    data () {
      this.inSearchList = []
      this.inSearchTotal = {}
      this.more = false
      this.loader = false
    }
  },
  methods: {
    async getMore () {
      this.emitter.emit('Loading', true)
      if (this.inSearchList.length === 0) {
        const reqData = JSON.parse(JSON.stringify(this.reqData))
        const memId = this.data.memId
        reqData.searchType = 'INNER'
        reqData.searchMemId = memId

        const today = getDateStr(new Date(), 'yyyy-MM-dd')
        if (this.reqData.endDate === today) {
          reqData.chkTodayYn = 'Y'
        } else {
          reqData.chkTodayYn = 'N'
        }

        const res = await calculateSearch(reqData)
        console.log('1111 : ', res)
        if (res.resultCode === '0') {
          this.inSearchList = res.data.inSearchList.filter((item, index) => {
            if (this.type !== 'user') {
              if (item.partnerLevel !== 'NORMAL') {
                return item
              } else {
                return null
              }
            } else {
              return item
            }
          })
          this.inSearchTotal = res.data.inSearchTotal
          console.log('res.data.inSearchTotal :::: ', res.data)
          this.inSearchTotal.casinoLosingAmt = ((this.inSearchTotal.casinoBetAmt - this.inSearchTotal.casinoBetAmtWin) - this.inSearchTotal.casinoRollingAmt) * (this.inSearchTotal.casinoLoseRate * 0.01)
          this.inSearchTotal.slotLosingAmt = (this.inSearchTotal.slotBetAmt - this.inSearchTotal.slotBetAmtWin - this.inSearchTotal.slotRollingAmt) * (this.inSearchTotal.slotLoseRate * 0.01)
          this.inSearchTotal.miniLosingAmt = (this.inSearchTotal.miniBetAmt - this.inSearchTotal.miniBetAmtWin - this.inSearchTotal.miniRollingAmt) * (this.inSearchTotal.miniLoseRate * 0.01)
          this.inSearchTotal.sportLosingAmt = (this.inSearchTotal.sportBetAmt - this.inSearchTotal.sportBetAmtWin - this.inSearchTotal.sportRollingAmt) * (this.inSearchTotal.sportLoseRate * 0.01)

          setTimeout(function () {
            this.loader = true
          }.bind(this), 500)
        }
      } else {
        this.inSearchList = []
        this.inSearchTotal = {}
      }
      this.emitter.emit('Loading', false)
    }
  }
}
</script>

<style scoped>
.block {display: block; font-size: 11px;}
.block a {cursor: pointer; color: #585963;}
.lRoll span {display: block;}
.lRoll span:first-child {margin-bottom: 6px;}
.lRoll em {font-size: 12px;}
.left {text-align: left;padding-left: 5px;}
.mainTable tr {transition: all .4s ease-in-out; position: relative; }
.mainTable tr::after {content: ''; display: inline-block; position: absolute; width: 100%; height: 1px; bottom:1px; left: 0; background: #a3a3a3; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);}
/* .mainTable .moreTable tr::after {display: none;} */
.mainTable tr:hover td {background: #fff;}
.mainTable .name .box {color: #fff;border-radius: 2px;display: inline-block; box-sizing: border-box; width: 38px; height: 20px; text-align: center; line-height: 20px; padding: 0; border: 0.5px solid #000;  font-size: 10px; }
.mainTable .name {color: #2b476e;}
.mainTable .name .box {background: #2b476e;}
.mainTable .moreTable .name {color: #363b72; overflow-x: scroll; }
.mainTable .moreTable .name::-webkit-scrollbar {height: 7px;}
.mainTable .moreTable .name::-webkit-scrollbar-thumb {background: #d4d4d4; border-radius: 8px;}
.mainTable .moreTable .name .box {background: #363b72;}
.mainTable .moreTable .moreTable .name {color: #1d505c;}
.mainTable .moreTable .moreTable .name .box {background: #1d505c;}
.mainTable .moreTable .moreTable .moreTable .name {color: #af2a2e;}
.mainTable .moreTable .moreTable .moreTable .name .box {background: #af2a2e;}
.mainTable .moreTable .moreTable .moreTable .moreTable .name {color: #d98f00;}
.mainTable .moreTable .moreTable .moreTable .moreTable .name .box {background: #d98f00;}
.mainTable .moreTable .moreTable .moreTable .moreTable .moreTable .name {color: #b53f3f;}
.mainTable .moreTable .moreTable .moreTable .moreTable .moreTable .name .box {background: #b53f3f;}
.mainTable td {border-bottom: 3px solid #efefef; border-right: 3px solid #efefef;}
.mainTable tr:last-child td { border-bottom: 0; }
.ratebox {padding: 0 10px;max-height: 120px;overflow-y: auto;font-size: 12px;}
.ratebox::-webkit-scrollbar {
    width: 5px;
  }
  .ratebox::-webkit-scrollbar-thumb {
    background-color: #82a6a4;
    border-radius: 5px;
    background-clip: padding-box;
    border: 1px solid transparent;
    box-sizing: border-box;
  }
  .ratebox::-webkit-scrollbar-track {
    background-color: #e1e0e0;
    border-radius: 5px;
  }
.rateWrap {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.rateWrap > li {
  display: flex;
  justify-content: space-between;
  width: 45px;
  padding: 5px 0;
}
.rateWrap > li:first-child {
  width: 50px;
}
.rateWrap > li > span {
  width: 55px;
  display: inline-block;
  text-align: left;
}
.sub{
  color:#0069cc;
}
.moreTable .moreBtns {left: 24px;}
.moreTable .moreTable .moreBtns {left: 39px;}
.moreTable .moreTable .moreTable .moreBtns {left: 54px;}
.moreTable .moreTable .moreTable .moreTable .moreBtns {left: 69px;}
.border-bot {border-bottom: 3px solid #efefef;}

.box.NORMAL {background: #df663a !important;}
.box.PTN_1 {background: #2b476e !important;}
.box.PTN_2 {background: #363b72 !important;}
.box.PTN_3 {background: #1d505c !important;}
.box.PTN_4 {background: #af2a2e !important;}
.box.PTN_5 {background: #d98f00 !important;}

</style>
