<template>
  <div class="s-memo-wrap">
    <a class="s-memo-btn" @click="openMemo"><img src="@/assets/img/box-memo.svg" /></a>
    <div class="s-memo-box" v-if="isOpenMemo">
      <a class="s-memo-close" @click="isOpenMemo = false;"></a>
      <textarea name="name" rows="8" cols="80" v-model="memo"></textarea>
      <a class="s-memo-save" @click="saveMemo">{{ $t("common.save") }}</a>
    </div>
  </div>
</template>

<script>

import { getMemo, setMemo } from '@/api/common'

export default {
  name: 'MemoUi',
  data () {
    return {
      isOpenMemo: false,
      memo: ''
    }
  },
  methods: {
    openMemo () {
      this.memo = ''
      const params = {}
      getMemo(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.memo = result.data.memoInfo ? result.data.memoInfo.siteMemo : ''
          this.isOpenMemo = true
        }
      })
    },
    saveMemo () {
      const params = {
        siteMemo: this.memo
      }
      setMemo(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.memo = result.data.memoInfo ? result.data.memoInfo.siteMemo : ''
          this.isOpenMemo = false
        }
      })
    }
  }
}
</script>
